<template>
  <div class="container is-fluid pl-0">

    <div class="columns ml-0">
      <div class="column is-3 is-color-palete-blue-4 pl-2 pt-3  dashboard-left-fixed-wrap" style="">
        <div class="">
          <div class="mb-2">
            <!-- <p class="title is-2 " style="color:#1f2d3b;">
              TETRIS
            </p> -->
            <h1 class="is-size-2 my-3" style="color:#1f2d3b;"><i class="fab fa-deezer mr-2"></i>Tetris</h1>
          </div>
          <!-- Changed proj table -->
          <dashboard-left-proj-table
            v-if="is_module_visible('project_list') && modules_data.my_project_list"></dashboard-left-proj-table>
        </div>
      </div>
      <div class="column is-9 is-offset-3">
        <div class="columns">
          <div class="column is-3" style="position:relative;">
            <toggle-panel-block></toggle-panel-block>
          </div>
          <div class="column is-6 pl-0 pt-2" style="position:relative;">
            <dashboard-projects-search></dashboard-projects-search>
          </div>
        </div>
        <div class="columns">

          <div class="column is-8">
            <!-- TABLE BLOCK -->
            <div class="columns">
              <div class="column">
                <!-- Today's Newsfeed -->
                <div class="custom_scrollbar-1">
                  <dashboard-today-newsfeed
                    v-if="is_module_visible('newsfeed') && modules_data.my_newsfeed"></dashboard-today-newsfeed>
                </div>
                <!-- Pending requests section -->
                <div class="custom_scrollbar-1">
                  <dashboard-pending-request
                    v-if="is_module_visible('pending_requests') && modules_data.my_pending_requests"></dashboard-pending-request>
                </div>
              </div>
            </div>
          </div>
          <!-- RIGHT PANEL -->
          <div class="column is-4 ml-1 is-3">
            <section class="hero has-border-bottom-blue-2 welcome is-small mb-2">
              <div class="p-2">
                <div class="container">
                  <h1 class="title is-size-6">
                    <i class="fas fa-truck-moving mr-1 is-text-palete-blue-1"></i>
                    {{ $t("truck_events") }}
                    <i @click.prevent="getMyModuleData('loading_trucks')"
                      class="fas fa-sync-alt is-pulled-right is-clickable ml-3"></i>
                  </h1>
                </div>
              </div>
            </section>
            <!-- Truck loading today -->

            <dashboard-loading-trucks v-if="is_module_visible('loading_trucks')"></dashboard-loading-trucks>
            <dashboard-delivered v-if="is_module_visible('delivered')"></dashboard-delivered>
            <dashboard-loading-return v-if="is_module_visible('loading_return')"></dashboard-loading-return>
            <!-- Truck returning today -->
            <dashboard-returning-trucks v-if="is_module_visible('returning_trucks')"></dashboard-returning-trucks>
            <!-- Truck returned -->
            <dashboard-returned-trucks v-if="is_module_visible('returned')"></dashboard-returned-trucks>

          </div>
        </div>

      </div>
    </div>



  </div>
</template>

<script>
import axios from 'axios'
export default {
  components: {
    'toggle-panel-block': () => import('@/components/dashboard/toggle-panel-block.vue'),
    'dashboard-projects-search': () => import('@/components/dashboard/dashboard-projects-search.vue'),
    'dashboard-left-proj-table': () => import('@/components/dashboard/dashboard-left-proj-table.vue'),
    'dashboard-today-newsfeed': () => import('@/components/dashboard/dashboard-today-newsfeed.vue'),
    'dashboard-pending-request': () => import('@/components/dashboard/dashboard-pending-request.vue'),

    // Trucks
    'dashboard-loading-trucks': () => import('@/components/dashboard/dashboard-loading-trucks.vue'),
    'dashboard-returning-trucks': () => import('@/components/dashboard/dashboard-returning-trucks.vue'),
    'dashboard-returned-trucks': () => import('@/components/dashboard/dashboard-returned-trucks.vue'),
    'dashboard-delivered': () => import('@/components/dashboard/dashboard-delivered.vue'),
    'dashboard-loading-return': () => import('@/components/dashboard/dashboard-loading-return.vue'),


  },
  data() {
    return {
      // modules:  ["projects", "newsfeed", "pending_requests", "loading_trucks", "returning_trucks", "returned"],
      modules: [],
      modules_data: {
        "my_project_list": [],
        "my_newsfeed": [],
        "my_pending_requests": [],
        "my_loading_trucks": [],
        "my_returning_trucks": [],
        "my_returned": [],
      },
      show_search_results: false,
      show_search_1: false,
      show_configure_panel: false,
      show_empty_panel_warning: false,
      selected_project_id: 0,
      tetris_search_text: '',
    }
  },
  methods: {
    searchProjects() {
      // this.$parent.getProjects(this.search, this.search_mode)
    },
    user_name() {
      var o = ''
      if (JSON.parse(localStorage.getItem("user"))) {
        o = JSON.parse(localStorage.getItem("user")).UserDisplayName
      }
      return o
    },
    getMyModuleData(d) {
      this.modules_data["my_" + d] = []
      this.getMyModuleRequest(d).then(r => {
        if (r.data.length > 0)
          r.data = r.data.map(aa => {
            if (aa.truck_name) {
              aa.truck_name = JSON.parse(aa.truck_name)[0].value
            }
            return aa
          })
        this.modules_data["my_" + d] = [...r.data]
        // console.log('this.modules_data["my_" + d]', this.modules_data["my_" + d])
      })

      // if(d == 'loading_trucks'){
      //   this.modules_data.my_loading_trucks = [...this.modules_data.my_returned.filter(t => t.event_type == 'loading-deliver')]
      // } else if(d == 'returning_trucks'){
      //   this.modules_data.my_returning_trucks = [...this.modules_data.my_returned.filter(t => t.event_type == 'returning')]
      // } else if(d == 'returned'){
      //   this.modules_data.my_returned = [...this.modules_data.my_returned.filter(t => t.event_type == 'returned')]
      // }
    },
    getMyModuleRequest(d) {
      return axios.post(this.$tetris_server + '/get/dashboard/' + d, { last_login: localStorage.last_login })
      // .then(res=> {
      //   console.log('Got this', res.data)
      // })
    },
    get_dashboard_data_1() {
      this.modules.forEach(e => {
        this.getMyModuleData(e.module_name)
      })
    },
    get_dashboard_modules_data() {
      var user_id = JSON.parse(localStorage.getItem("tetris_user")).ej_id

      axios.post(this.$tetris_server + '/get/dashboard/modules/data', { user_id: user_id })
        .then(res => {
          if (res.data) {
            let found = false
            res.data.map(mm => {
              if (mm.is_visible == 1) {
                found = true
              }
            })
            if (found == false) {
              this.show_configure_panel = true
            }

            this.modules = res.data.map(m => {
              m.is_loading = false
              return m
            })
            // console.log('this.modules', this.modules)
            this.get_dashboard_data_1()

          }
        })
    },
    toggle_module(m) {
      m.is_loading = true
      var user_id = JSON.parse(localStorage.getItem("user")).IdUser

      axios.post(this.$tetris_server + '/toggle/module', { user_id: user_id, module: m })
        .then(res => {

          if (res.data[0] == 'create') {
            m.is_loading = false
            this.modules.forEach(mod => {
              if (mod.id == res.data[1].module_id) {
                mod.is_visible = 1
                mod.module_id = res.data[1].module_id
                m.is_loading = false
              }
            })
          } else if (res.data[0] == 'delete') {
            this.modules.forEach(mod => {
              if (mod.id == res.data[1].id) {
                mod.is_visible = 0
                mod.module_id = res.data[1].id
                m.is_loading = false
              }
            })
          }
        })
        .catch(
          function () {
            m.is_loading = false
          }
        )
    },
    is_module_visible(m) {
      var v = false
      this.modules.map(mod => {
        if (mod.module_name == m) {
          if (mod.is_visible == 1) return v = true
          else return v = false
        }
      })
      return v
    },
  },

  computed: {
    // get one of the keys in the modules_data... each key is the array that each block uses

    modules_data_value() {
      return this.modules_data
    },
    is_pannel_empty() {
      let found = true
      this.modules.map(mm => {
        if (mm.is_visible == 1) {
          found = false
        }
      })
      return found
    }
  },

  created() {
    setTimeout(() => { this.$parent.getProjects("") }, 500)

    document.title = "TETRIS - Dashboard"
    // this.get_dashboard_data_1()
    this.get_dashboard_modules_data()

    this.$parent.app_lang = this.$i18n.locale
    this.$parent.app_route = this.$parent.get_url()
  }
}
</script>

<style lang="scss" scoped>
footer {
  background: #48c78e;
}

.card-footer-item {
  color: white;
}

.circle {
  border-radius: 12px;
  width: 24px;
  height: 24px;
  display: block;
  float: left;
  margin-right: 4px;
}

input {
  color: #171717;
}

.search-bar {
  display: flex;
}

.search-bar input,
.search-btn,
.search-btn:before,
.search-btn:after {
  transition: all 0.25s ease-out;
}

.search-bar input,
.search-btn {
  width: 3em;
  height: 3em;
}

.search-bar input:invalid:not(:focus),
.search-btn {
  cursor: pointer;
}

.search-bar,
.search-bar input:focus,
.search-bar input:valid {
  width: 100%;
}

.search-bar input:focus,
.search-bar input:not(:focus)+.search-btn:focus {
  outline: transparent;
}

.search-bar {
  margin: auto;
  padding: 1.5em;
  justify-content: center;
  max-width: 30em;
}

.search-bar input {
  background: transparent;
  border-radius: 1.5em;
  box-shadow: 0 0 0 0.4em #171717 inset;
  padding: 0.75em;
  transform: translate(0.5em, 0.5em) scale(0.5);
  transform-origin: 100% 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-bar input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.search-bar input:focus,
.search-bar input:valid {
  background: #fff;
  border-radius: 0.375em 0 0 0.375em;
  box-shadow: 0 0 0 0.1em #d9d9d9 inset;
  transform: scale(1);
}

.search-btn {
  background: #171717;
  border-radius: 0 0.75em 0.75em 0 / 0 1.5em 1.5em 0;
  padding: 0.75em;
  position: relative;
  transform: translate(0.25em, 0.25em) rotate(45deg) scale(0.25, 0.125);
  transform-origin: 0 50%;
}

.search-btn:before,
.search-btn:after {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
}

.search-btn:before {
  border-radius: 50%;
  box-shadow: 0 0 0 0.2em #f1f1f1 inset;
  top: 0.75em;
  left: 0.75em;
  width: 1.2em;
  height: 1.2em;
}

.search-btn:after {
  background: #f1f1f1;
  border-radius: 0 0.25em 0.25em 0;
  top: 51%;
  left: 51%;
  width: 0.75em;
  height: 0.25em;
  transform: translate(0.2em, 0) rotate(45deg);
  transform-origin: 0 50%;
}

.search-btn span {
  display: inline-block;
  overflow: hidden;
  width: 1px;
  height: 1px;
}

/* Active state */
.search-bar input:focus+.search-btn,
.search-bar input:valid+.search-btn {
  background: #008cff;
  border-radius: 0 0.375em 0.375em 0;
  transform: scale(1);
}

.search-bar input:focus+.search-btn:before,
.search-bar input:focus+.search-btn:after,
.search-bar input:valid+.search-btn:before,
.search-bar input:valid+.search-btn:after {
  opacity: 1;
}

.search-bar input:focus+.search-btn:hover,
.search-bar input:valid+.search-btn:hover,
.search-bar input:valid:not(:focus)+.search-btn:focus {
  background: #0c48db;
}

.search-bar input:focus+.search-btn:active,
.search-bar input:valid+.search-btn:active {
  transform: translateY(1px);
}

.dashboard-left-fixed-wrap {
  position: fixed;
  top: 0;
  // overflow-y: scroll;
  height: 100vh;
}
</style>
